var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ListWalker from './ListWalker';
var HOVER_CLASS = "-openreplay-hover";
var HOVER_CLASS_DEPR = "-asayer-hover";
var MouseManager = /** @class */ (function (_super) {
    __extends(MouseManager, _super);
    function MouseManager(screen) {
        var _this = _super.call(this) || this;
        _this.screen = screen;
        _this.hoverElements = [];
        return _this;
    }
    MouseManager.prototype.updateHover = function () {
        var _this = this;
        // @ts-ignore TODO
        var curHoverElements = this.screen.getCursorTargets();
        var diffAdd = curHoverElements.filter(function (elem) { return !_this.hoverElements.includes(elem); });
        var diffRemove = this.hoverElements.filter(function (elem) { return !curHoverElements.includes(elem); });
        this.hoverElements = curHoverElements;
        diffAdd.forEach(function (elem) {
            elem.classList.add(HOVER_CLASS);
            elem.classList.add(HOVER_CLASS_DEPR);
        });
        diffRemove.forEach(function (elem) {
            elem.classList.remove(HOVER_CLASS);
            elem.classList.remove(HOVER_CLASS_DEPR);
        });
    };
    MouseManager.prototype.reset = function () {
        this.hoverElements = [];
    };
    MouseManager.prototype.move = function (t) {
        var lastMouseMove = this.moveToLast(t);
        if (!!lastMouseMove) {
            // @ts-ignore TODO
            this.screen.cursor.move(lastMouseMove);
            this.updateHover();
        }
    };
    return MouseManager;
}(ListWalker));
export default MouseManager;
