var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import logger from 'App/logger';
import readMessage from './messages';
import PrimitiveReader from './PrimitiveReader';
// function needSkipMessage(data: Uint8Array, p: number, pLast: number): boolean {
//   for (let i = 7; i >= 0; i--) {
//     if (data[ p + i ] !== data[ pLast + i ]) {
//       return data[ p + i ] - data[ pLast + i ] < 0
//     }
//   }
//   return true
// }
var MessageReader = /** @class */ (function (_super) {
    __extends(MessageReader, _super);
    function MessageReader(data, startTime) {
        var _this = _super.call(this, data) || this;
        _this.startTime = startTime;
        _this.pLastMessageID = 0;
        _this.currentTime = 0;
        _this.error = false;
        return _this;
    }
    MessageReader.prototype.needSkipMessage = function () {
        if (this.p === 0)
            return false;
        for (var i = 7; i >= 0; i--) {
            if (this.buf[this.p + i] !== this.buf[this.pLastMessageID + i]) {
                return this.buf[this.p + i] - this.buf[this.pLastMessageID + i] < 0;
            }
        }
        return true;
    };
    MessageReader.prototype.readMessage = function () {
        this.skip(8);
        try {
            var msg = void 0;
            msg = readMessage(this);
            return msg;
        }
        catch (e) {
            this.error = true;
            logger.error("Read message error:", e);
            return null;
        }
    };
    MessageReader.prototype.hasNext = function () {
        return !this.error && this.buf.length > this.p;
    };
    MessageReader.prototype.next = function () {
        if (!this.hasNext()) {
            return null;
        }
        while (this.needSkipMessage()) {
            this.readMessage();
        }
        this.pLastMessageID = this.p;
        var msg = this.readMessage();
        if (!msg) {
            return null;
        }
        if (msg.tp === "timestamp") {
            // if (this.startTime == null) {
            // 	this.startTime = msg.timestamp
            // }
            this.currentTime = msg.timestamp - this.startTime;
        }
        else {
            var tMsg = Object.assign(msg, {
                time: this.currentTime,
                _index: this.pLastMessageID,
            });
            return [tMsg, this.pLastMessageID];
        }
        return null;
    };
    return MessageReader;
}(PrimitiveReader));
export default MessageReader;
