var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import logger from 'App/logger';
import StylesManager, { rewriteNodeStyleSheet } from './StylesManager';
import ListWalker from './ListWalker';
var IGNORED_ATTRS = ["autocomplete", "name"];
var ATTR_NAME_REGEXP = /([^\t\n\f \/>"'=]+)/; // regexp costs ~
var DOMManager = /** @class */ (function (_super) {
    __extends(DOMManager, _super);
    function DOMManager(screen, isMobile, startTime) {
        var _this = _super.call(this) || this;
        _this.nl = [];
        _this.isLink = []; // Optimisations
        _this.bodyId = -1;
        _this.postponedBodyMessage = null;
        _this.nodeScrollManagers = [];
        _this.applyMessage = function (msg) {
            var node;
            var doc;
            switch (msg.tp) {
                case "create_document":
                    doc = _this.screen.document;
                    if (!doc) {
                        logger.error("No iframe document found", msg);
                        return;
                    }
                    doc.open();
                    doc.write("<!DOCTYPE html><html></html>");
                    doc.close();
                    var fRoot = doc.documentElement;
                    fRoot.innerText = '';
                    _this.nl = [fRoot];
                    // the last load event I can control
                    //if (this.document.fonts) {
                    //  this.document.fonts.onloadingerror = () => this.marker.redraw();
                    //  this.document.fonts.onloadingdone = () => this.marker.redraw();
                    //}
                    //this.screen.setDisconnected(false);
                    _this.stylesManager.reset();
                    break;
                case "create_text_node":
                    _this.nl[msg.id] = document.createTextNode('');
                    _this.insertNode(msg);
                    break;
                case "create_element_node":
                    // console.log('elementnode', msg)
                    if (msg.svg) {
                        _this.nl[msg.id] = document.createElementNS('http://www.w3.org/2000/svg', msg.tag);
                    }
                    else {
                        _this.nl[msg.id] = document.createElement(msg.tag);
                    }
                    if (_this.bodyId === msg.id) {
                        _this.postponedBodyMessage = msg;
                    }
                    else {
                        _this.insertNode(msg);
                    }
                    _this.removeBodyScroll(msg.id);
                    _this.removeAutocomplete(msg);
                    break;
                case "move_node":
                    _this.insertNode(msg);
                    break;
                case "remove_node":
                    node = _this.nl[msg.id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    if (!node.parentElement) {
                        logger.error("Parent node not found", msg);
                        break;
                    }
                    node.parentElement.removeChild(node);
                    break;
                case "set_node_attribute":
                    var id = msg.id, name_1 = msg.name, value = msg.value;
                    node = _this.nl[id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    if (_this.isLink[id] && name_1 === "href") {
                        // @ts-ignore TODO: global ENV type
                        if (value.startsWith(window.ENV.ASSETS_HOST)) { // Hack for queries in rewrited urls
                            value = value.replace("?", "%3F");
                        }
                        _this.stylesManager.setStyleHandlers(node, value);
                    }
                    try {
                        node.setAttribute(name_1, value);
                    }
                    catch (e) {
                        logger.error(e, msg);
                    }
                    _this.removeBodyScroll(msg.id);
                    break;
                case "remove_node_attribute":
                    if (!_this.nl[msg.id]) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    try {
                        _this.nl[msg.id].removeAttribute(msg.name);
                    }
                    catch (e) {
                        logger.error(e, msg);
                    }
                    break;
                case "set_input_value":
                    if (!_this.nl[msg.id]) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    var val = msg.mask > 0 ? '*'.repeat(msg.mask) : msg.value;
                    _this.nl[msg.id].value = val;
                    break;
                case "set_input_checked":
                    node = _this.nl[msg.id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    node.checked = msg.checked;
                    break;
                case "set_node_data":
                case "set_css_data":
                    node = _this.nl[msg.id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    // @ts-ignore
                    node.data = msg.data;
                    if (node instanceof HTMLStyleElement) {
                        doc = _this.screen.document;
                        doc && rewriteNodeStyleSheet(doc, node);
                    }
                    break;
                case "css_insert_rule":
                    node = _this.nl[msg.id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    if (!(node instanceof HTMLStyleElement) // link or null
                        || node.sheet == null) {
                        logger.warn("Non-style node in  CSS rules message (or sheet is null)", msg);
                        break;
                    }
                    try {
                        node.sheet.insertRule(msg.rule, msg.index);
                    }
                    catch (e) {
                        logger.warn(e, msg);
                        try {
                            node.sheet.insertRule(msg.rule);
                        }
                        catch (e) {
                            logger.warn("Cannot insert rule.", e, msg);
                        }
                    }
                    break;
                case "css_delete_rule":
                    node = _this.nl[msg.id];
                    if (!node) {
                        logger.error("Node not found", msg);
                        break;
                    }
                    if (!(node instanceof HTMLStyleElement) // link or null
                        || node.sheet == null) {
                        logger.warn("Non-style node in  CSS rules message (or sheet is null)", msg);
                        break;
                    }
                    try {
                        node.sheet.deleteRule(msg.index);
                    }
                    catch (e) {
                        logger.warn(e, msg);
                    }
                    break;
                case "create_i_frame_document":
                    node = _this.nl[msg.frameID];
                    // console.log('ifr', msg, node)
                    if (node instanceof HTMLIFrameElement) {
                        doc = node.contentDocument;
                        if (!doc) {
                            logger.warn("No iframe doc", msg, node, node.contentDocument);
                            return;
                        }
                        _this.nl[msg.id] = doc.documentElement;
                        return;
                    }
                    else if (node instanceof Element) { // shadow DOM
                        try {
                            _this.nl[msg.id] = node.attachShadow({ mode: 'open' });
                        }
                        catch (e) {
                            logger.warn("Can not attach shadow dom", e, msg);
                        }
                    }
                    else {
                        logger.warn("Context message host is not Element", msg);
                    }
                    break;
                //not sure what to do with this one
                //case "disconnected":
                //setTimeout(() => {
                // if last one
                //if (this.msgs[ this.msgs.length - 1 ] === msg) {
                //  this.setDisconnected(true);
                // }
                //}, 10000);
                //break;
            }
        };
        _this.startTime = startTime;
        _this.isMobile = isMobile;
        _this.screen = screen;
        _this.stylesManager = new StylesManager(screen);
        return _this;
    }
    Object.defineProperty(DOMManager.prototype, "time", {
        get: function () {
            return this.startTime;
        },
        enumerable: false,
        configurable: true
    });
    DOMManager.prototype.add = function (m) {
        switch (m.tp) {
            case "set_node_scroll":
                if (!this.nodeScrollManagers[m.id]) {
                    this.nodeScrollManagers[m.id] = new ListWalker();
                }
                this.nodeScrollManagers[m.id].add(m);
                return;
            //case "css_insert_rule": // ||   //set_css_data ???
            //case "css_delete_rule":
            // (m.tp === "set_node_attribute" && this.isLink[ m.id ] && m.key === "href")) {
            //  this.stylesManager.add(m);
            //  return;
            default:
                if (m.tp === "create_element_node") {
                    switch (m.tag) {
                        case "LINK":
                            this.isLink[m.id] = true;
                            break;
                        case "BODY":
                            this.bodyId = m.id; // Can be several body nodes at one document session?
                            break;
                    }
                }
                else if (m.tp === "set_node_attribute" &&
                    (IGNORED_ATTRS.includes(m.name) || !ATTR_NAME_REGEXP.test(m.name))) {
                    logger.log("Ignorring message: ", m);
                    return; // Ignoring...
                }
                _super.prototype.add.call(this, m);
        }
    };
    DOMManager.prototype.removeBodyScroll = function (id) {
        if (this.isMobile && this.bodyId === id) {
            this.nl[id].style.overflow = "hidden";
        }
    };
    // May be make it as a message on message add? 
    DOMManager.prototype.removeAutocomplete = function (_a) {
        var id = _a.id, tag = _a.tag;
        var node = this.nl[id];
        if (["FORM", "TEXTAREA", "SELECT"].includes(tag)) {
            node.setAttribute("autocomplete", "off");
            return true;
        }
        if (tag === "INPUT") {
            node.setAttribute("autocomplete", "new-password");
            return true;
        }
        return false;
    };
    // type = NodeMessage ?
    DOMManager.prototype.insertNode = function (_a) {
        var parentID = _a.parentID, id = _a.id, index = _a.index;
        if (!this.nl[id]) {
            logger.error("Insert error. Node not found", id);
            return;
        }
        if (!this.nl[parentID]) {
            logger.error("Insert error. Parent node not found", parentID);
            return;
        }
        // WHAT if text info contains some rules and the ordering is just wrong???
        var el = this.nl[parentID];
        if ((el instanceof HTMLStyleElement) && // TODO: correct ordering OR filter in tracker
            el.sheet &&
            el.sheet.cssRules &&
            el.sheet.cssRules.length > 0) {
            logger.log("Trying to insert child to style tag with virtual rules: ", this.nl[parentID], this.nl[id]);
            return;
        }
        var childNodes = this.nl[parentID].childNodes;
        if (!childNodes) {
            logger.error("Node has no childNodes", this.nl[parentID]);
            return;
        }
        this.nl[parentID]
            .insertBefore(this.nl[id], childNodes[index]);
    };
    DOMManager.prototype.moveReady = function (t) {
        var _this = this;
        this.moveApply(t, this.applyMessage); // This function autoresets pointer if necessary (better name?)
        this.nodeScrollManagers.forEach(function (manager) {
            var msg = manager.moveToLast(t); // TODO: reset (?)
            if (!!msg && !!_this.nl[msg.id]) {
                var node = _this.nl[msg.id];
                node.scrollLeft = msg.x;
                node.scrollTop = msg.y;
            }
        });
        /* Mount body as late as possible */
        if (this.postponedBodyMessage != null) {
            this.insertNode(this.postponedBodyMessage);
            this.postponedBodyMessage = null;
        }
        // Thinkabout (read): css preload
        // What if we go back before it is ready? We'll have two handlres?
        return this.stylesManager.moveReady(t);
    };
    return DOMManager;
}(ListWalker));
export default DOMManager;
