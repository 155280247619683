import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import SessionItem from 'Shared/SessionItem'
import { fetchSessions, fetchSessionsFiltered } from 'Duck/funnels'
import { LoadMoreButton, NoContent, Loader } from 'UI'
import FunnelSessionsHeader from '../FunnelSessionsHeader'
import WidgetSection from 'Shared/WidgetSection/WidgetSection';
import cn from 'classnames';
import { TimeInFunnel } from 'Dashboard/Widgets';

import WidgetHolder from 'Dashboard/WidgetHolder/WidgetHolder';

const PER_PAGE = 10;

function FunnelSessionMetrics(props) {
  const { list, dashboardAppearance, comparing, sessionsTotal, sessionsSort, funnel, inDetails = false } = props;

  const [showPages, setShowPages] = useState(1)
  const displayedCount = Math.min(showPages * PER_PAGE, list.size);

  const addPage = () => setShowPages(showPages + 1);

  return (
    <WidgetSection title="Funnel Metrics" type="performance" className="mb-4">
        <div className={ cn("gap-4", { 'grid grid-cols-2' : !comparing })}>
        <WidgetHolder Component={TimeInFunnel} params={{"funnelId": funnel["funnelId"]}} />
        </div>
    </WidgetSection>
  )
}

export default connect(state => ({  
  list: state.getIn(['funnels', 'sessions']),
  sessionsTotal: state.getIn(['funnels', 'sessionsTotal']),  
  funnel: state.getIn(['funnels', 'instance']),
  activeStages: state.getIn(['funnels', 'activeStages']).toJS(),
  liveFilters: state.getIn(['funnelFilters', 'appliedFilter']),
  funnelFilters: state.getIn(['funnels', 'funnelFilters']),
  sessionsSort: state.getIn(['funnels', 'sessionsSort']),
  dashboardAppearance: state.getIn([ 'user', 'account', 'appearance', 'dashboard' ]),
  comparing: state.getIn([ 'dashboard', 'comparing' ]),
}), { fetchSessions, fetchSessionsFiltered })(FunnelSessionMetrics)
