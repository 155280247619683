var PrimitiveReader = /** @class */ (function () {
    function PrimitiveReader(buf) {
        this.buf = buf;
        this.p = 0;
    }
    PrimitiveReader.prototype.hasNext = function () {
        return this.p < this.buf.length;
    };
    PrimitiveReader.prototype.readUint = function () {
        var r = 0, s = 1, b;
        do {
            b = this.buf[this.p++];
            r += (b & 0x7F) * s;
            s *= 128;
        } while (b >= 0x80);
        return r;
    };
    PrimitiveReader.prototype.readInt = function () {
        var u = this.readUint();
        if (u % 2) {
            u = (u + 1) / -2;
        }
        else {
            u = u / 2;
        }
        return u;
    };
    PrimitiveReader.prototype.readString = function () {
        var l = this.readUint();
        return new TextDecoder().decode(this.buf.subarray(this.p, this.p += l));
    };
    PrimitiveReader.prototype.readBoolean = function () {
        return !!this.buf[this.p++];
    };
    PrimitiveReader.prototype.skip = function (n) {
        this.p += n;
    };
    return PrimitiveReader;
}());
export default PrimitiveReader;
