// Auto-generated, do not edit
export var ID_TP_MAP = {
    0: "timestamp",
    2: "session_disconnect",
    4: "set_page_location",
    5: "set_viewport_size",
    6: "set_viewport_scroll",
    7: "create_document",
    8: "create_element_node",
    9: "create_text_node",
    10: "move_node",
    11: "remove_node",
    12: "set_node_attribute",
    13: "remove_node_attribute",
    14: "set_node_data",
    15: "set_css_data",
    16: "set_node_scroll",
    18: "set_input_value",
    19: "set_input_checked",
    20: "mouse_move",
    22: "console_log",
    37: "css_insert_rule",
    38: "css_delete_rule",
    39: "fetch",
    40: "profiler",
    41: "o_table",
    44: "redux",
    45: "vuex",
    46: "mob_x",
    47: "ng_rx",
    48: "graph_ql",
    49: "performance_track",
    54: "connection_information",
    55: "set_page_visibility",
    59: "long_task",
    69: "mouse_click",
    70: "create_i_frame_document",
    90: "ios_session_start",
    93: "ios_custom_event",
    96: "ios_screen_changes",
    100: "ios_click_event",
    102: "ios_performance_event",
    103: "ios_log",
    105: "ios_network_call",
};
export default function (r) {
    var tp = r.readUint();
    switch (tp) {
        case 0:
            return {
                tp: ID_TP_MAP[0],
                timestamp: r.readUint(),
            };
        case 2:
            return {
                tp: ID_TP_MAP[2],
                timestamp: r.readUint(),
            };
        case 4:
            return {
                tp: ID_TP_MAP[4],
                url: r.readString(),
                referrer: r.readString(),
                navigationStart: r.readUint(),
            };
        case 5:
            return {
                tp: ID_TP_MAP[5],
                width: r.readUint(),
                height: r.readUint(),
            };
        case 6:
            return {
                tp: ID_TP_MAP[6],
                x: r.readInt(),
                y: r.readInt(),
            };
        case 7:
            return {
                tp: ID_TP_MAP[7],
            };
        case 8:
            return {
                tp: ID_TP_MAP[8],
                id: r.readUint(),
                parentID: r.readUint(),
                index: r.readUint(),
                tag: r.readString(),
                svg: r.readBoolean(),
            };
        case 9:
            return {
                tp: ID_TP_MAP[9],
                id: r.readUint(),
                parentID: r.readUint(),
                index: r.readUint(),
            };
        case 10:
            return {
                tp: ID_TP_MAP[10],
                id: r.readUint(),
                parentID: r.readUint(),
                index: r.readUint(),
            };
        case 11:
            return {
                tp: ID_TP_MAP[11],
                id: r.readUint(),
            };
        case 12:
            return {
                tp: ID_TP_MAP[12],
                id: r.readUint(),
                name: r.readString(),
                value: r.readString(),
            };
        case 13:
            return {
                tp: ID_TP_MAP[13],
                id: r.readUint(),
                name: r.readString(),
            };
        case 14:
            return {
                tp: ID_TP_MAP[14],
                id: r.readUint(),
                data: r.readString(),
            };
        case 15:
            return {
                tp: ID_TP_MAP[15],
                id: r.readUint(),
                data: r.readString(),
            };
        case 16:
            return {
                tp: ID_TP_MAP[16],
                id: r.readUint(),
                x: r.readInt(),
                y: r.readInt(),
            };
        case 18:
            return {
                tp: ID_TP_MAP[18],
                id: r.readUint(),
                value: r.readString(),
                mask: r.readInt(),
            };
        case 19:
            return {
                tp: ID_TP_MAP[19],
                id: r.readUint(),
                checked: r.readBoolean(),
            };
        case 20:
            return {
                tp: ID_TP_MAP[20],
                x: r.readUint(),
                y: r.readUint(),
            };
        case 22:
            return {
                tp: ID_TP_MAP[22],
                level: r.readString(),
                value: r.readString(),
            };
        case 37:
            return {
                tp: ID_TP_MAP[37],
                id: r.readUint(),
                rule: r.readString(),
                index: r.readUint(),
            };
        case 38:
            return {
                tp: ID_TP_MAP[38],
                id: r.readUint(),
                index: r.readUint(),
            };
        case 39:
            return {
                tp: ID_TP_MAP[39],
                method: r.readString(),
                url: r.readString(),
                request: r.readString(),
                response: r.readString(),
                status: r.readUint(),
                timestamp: r.readUint(),
                duration: r.readUint(),
            };
        case 40:
            return {
                tp: ID_TP_MAP[40],
                name: r.readString(),
                duration: r.readUint(),
                args: r.readString(),
                result: r.readString(),
            };
        case 41:
            return {
                tp: ID_TP_MAP[41],
                key: r.readString(),
                value: r.readString(),
            };
        case 44:
            return {
                tp: ID_TP_MAP[44],
                action: r.readString(),
                state: r.readString(),
                duration: r.readUint(),
            };
        case 45:
            return {
                tp: ID_TP_MAP[45],
                mutation: r.readString(),
                state: r.readString(),
            };
        case 46:
            return {
                tp: ID_TP_MAP[46],
                type: r.readString(),
                payload: r.readString(),
            };
        case 47:
            return {
                tp: ID_TP_MAP[47],
                action: r.readString(),
                state: r.readString(),
                duration: r.readUint(),
            };
        case 48:
            return {
                tp: ID_TP_MAP[48],
                operationKind: r.readString(),
                operationName: r.readString(),
                variables: r.readString(),
                response: r.readString(),
            };
        case 49:
            return {
                tp: ID_TP_MAP[49],
                frames: r.readInt(),
                ticks: r.readInt(),
                totalJSHeapSize: r.readUint(),
                usedJSHeapSize: r.readUint(),
            };
        case 54:
            return {
                tp: ID_TP_MAP[54],
                downlink: r.readUint(),
                type: r.readString(),
            };
        case 55:
            return {
                tp: ID_TP_MAP[55],
                hidden: r.readBoolean(),
            };
        case 59:
            return {
                tp: ID_TP_MAP[59],
                timestamp: r.readUint(),
                duration: r.readUint(),
                context: r.readUint(),
                containerType: r.readUint(),
                containerSrc: r.readString(),
                containerId: r.readString(),
                containerName: r.readString(),
            };
        case 69:
            return {
                tp: ID_TP_MAP[69],
                id: r.readUint(),
                hesitationTime: r.readUint(),
                label: r.readString(),
                selector: r.readString(),
            };
        case 70:
            return {
                tp: ID_TP_MAP[70],
                frameID: r.readUint(),
                id: r.readUint(),
            };
        case 90:
            return {
                tp: ID_TP_MAP[90],
                timestamp: r.readUint(),
                projectID: r.readUint(),
                trackerVersion: r.readString(),
                revID: r.readString(),
                userUUID: r.readString(),
                userOS: r.readString(),
                userOSVersion: r.readString(),
                userDevice: r.readString(),
                userDeviceType: r.readString(),
                userCountry: r.readString(),
            };
        case 93:
            return {
                tp: ID_TP_MAP[93],
                timestamp: r.readUint(),
                length: r.readUint(),
                name: r.readString(),
                payload: r.readString(),
            };
        case 96:
            return {
                tp: ID_TP_MAP[96],
                timestamp: r.readUint(),
                length: r.readUint(),
                x: r.readUint(),
                y: r.readUint(),
                width: r.readUint(),
                height: r.readUint(),
            };
        case 100:
            return {
                tp: ID_TP_MAP[100],
                timestamp: r.readUint(),
                length: r.readUint(),
                label: r.readString(),
                x: r.readUint(),
                y: r.readUint(),
            };
        case 102:
            return {
                tp: ID_TP_MAP[102],
                timestamp: r.readUint(),
                length: r.readUint(),
                name: r.readString(),
                value: r.readUint(),
            };
        case 103:
            return {
                tp: ID_TP_MAP[103],
                timestamp: r.readUint(),
                length: r.readUint(),
                severity: r.readString(),
                content: r.readString(),
            };
        case 105:
            return {
                tp: ID_TP_MAP[105],
                timestamp: r.readUint(),
                length: r.readUint(),
                duration: r.readUint(),
                headers: r.readString(),
                body: r.readString(),
                url: r.readString(),
                success: r.readBoolean(),
                method: r.readString(),
                status: r.readUint(),
            };
        default:
            throw new Error("Unrecognizable message type: " + tp);
            return null;
    }
}
